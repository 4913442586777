<template>
  <div
    :class="{
      'mr-1 universal-choice-selector': true,
      'w-100 px-3 mb-1': $vuetify.breakpoint.smAndDown,
    }"
  >
    <v-menu transition="slide-y-transition" bottom offset-y max-width="200px">
      <template #activator="{ on, attrs }">
        <v-btn
          class="choice-selector-btn elevation-0 px-2 w-100"
          height="30px"
          color="#dee7ee"
          dense
          v-bind="attrs"
          v-on="on"
        >
          {{
            items.filter((i) => i.value === value)[0].label || "Select a value"
          }}
          <v-icon right dark>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list class="universal-choice-selector-menu">
        <v-list-item-group :value="value" @change="$emit('input', $event)">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :value="item.value"
            class="px-4 d-flex justify-center align-center"
          >
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "BooleanSelector",
  props: {
    value: {
      default: () => true,
      type: Boolean,
    },
    items: {
      default: () => [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      type: Array,
    },
  },
  created() {
    this.$emit('input', this.value);
  }
};
</script>


<style lang="scss" scoped>
.choice-selector-btn {
  text-transform: none;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #66788e;
}
</style>